import Footer from "../Components/footer";
import Header from "../Components/Header";
import Form from "../Components/Form";
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";




const SmartcareProject = () => {

const smartcareimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/smartcareimg1.svg";
const smartcareimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/smartcareimg2.svg";
const smartpatientimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/smartpatientimg1.svg";
// const casestudyimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/casestudy-img3.svg";
const casestudyimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/casestudy-img4.svg";
// const casestudyimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/codetechimg4.svg";

// const vaibhav = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/vaibhavimg.svg";
const prashant = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/prashant.png";

const vaibhav = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/vaibhavimg.svg";

const rajesh = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/rajeshimg.svg";
const kritesh = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/kriteshimg.svg";
const ravi = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/raviimg.svg";
const harsh = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/harshimg.svg";
const projectimg11 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/healthomaxappimg-C.png';


const smartcareData=[
    { 


        heading:"HealthOMax App",

        title:"We developed the HealthOmax mobile application for both Android and iOS platforms. The app offers the same features as the website, allowing medical professionals to connect and collaborate, and patients to interact with doctors on the go.",
        bgcolor:"rgba(0, 51, 164, 1)",
        img:projectimg11,
         url:"/our-completed-projects/healthomax-mobile-app"

    },
];

    return (

        <div>
             <SEOPage 
                title="SmartCare Platform Development | SaaS-Based Clinic Management Solution"
                description="We developed SmartCare, a powerful SaaS-based clinic management platform, designed to streamline operations and enhance patient care in clinics."
                canonicalUrl="https://www.datametricks.com/our-completed-projects/smartcare"
            />
            
            <div>
                <Header/>
            </div>

         <section className="mt90" style={{marginTop:'130px'}}>
                <div className="container casestudycontainer position-relative">
                 <div className="image-wrapper">
                       <img src={smartpatientimg1} alt="" className="img-fluid w-100 casestudyimg-fluid" />
                      <div className="casestudytext-overlay text-center " >
                            <p  className="casestudyheadline mb-0">Creative Design and Development </p>
                            <p  className="casestudyheadline">for SmartCare </p>
                     </div>
                </div>
              </div>
        </section>

        <section>
            <div className="container text-center mt-4">
                <img src={smartcareimg1} alt="" className="img-fluid" />
            </div>
        </section>

        <section>
            <div className="container mt-lg-5 mt-30">
                <div className="row justify-content-center">
                    <div className="col-lg-5 textcenter">
                        <p className="fw-600 fs-24 plus font-24" style={{color:'rgba(64, 64, 64, 1)'}}>Project Summary</p>
                        <p className="fs-16 font-16 plus" style={{color:'rgba(82, 86, 101, 1)'}}>SmartCare is a healthcare platform designed to streamline patient-doctor interactions. It allows patients to book appointments, find doctors, store medical records, and track vital health information. </p>

                     
                      <div className="d-flex flex-wrap gap-3 justify">
                        <div>
                            <button type="button" class="btn  btn-sm font-16  fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Designing</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16  fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Developer</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm  font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Nuxt</button>
                        </div>
                     </div>
                     <div className="d-flex gap-3 pt-3 justify">
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Laravel</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>My SQL</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Php</button>
                        </div>
                        {/* <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Figma</button>
                        </div> */}
                      
                        
                     </div>
                        
                    </div>
                    <div className="col-lg-5 ps-lg-5 pl0 pl-0 ">
                        <p className="fw-600 fs-24 plus textcenter pt-20 font-24 mt-20" style={{color:'rgba(64, 64, 64, 1)'}}>Project Details</p>
                        <div className="p-4  " style={{backgroundColor:'rgba(251, 251, 251, 1)',borderRadius:'16px'}}>
                        <p className="fw-600 fs-16 plus mb-0 font-16" style={{color:'rgba(39, 39, 39, 1)'}}>Project Type : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>Complete Project Development    </span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Duration : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>Ongoing  </span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Website:  <a href="https://www.smartcare.health" className="fw-700 fs-15 plus font-16" style={{color:'rgba(115, 144, 249, 1)'}}>Click Here</a></p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Tools : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>Nuxt, Laravel, My SQL,Figma </span> </p>

                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Team : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>Vaibhav, Thurka, Kritesh, Ravi, Mihir, Harsh, Prashant    </span> </p>


                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="container mt-lg-5 mt-10">
        <div className="p-4 " style={{border:'0.90px solid rgba(211, 211, 211, 1)',borderRadius:'16px'}} >

            <div className="row text-center"> 
            <div className="d-block d-sm-block d-lg-none d-md-none">
                    <p className="fw-600 fs-24 font-23 plus  " style={{color:'rgba(64, 64, 64, 1)'}}>Team Member</p>
                </div>
           
            <div className="col-lg-2 col-md-6 col-6 d-none d-sm-none d-lg-block d-md-block">
                <div>
                    <p className="fw-600 fs-24 font-24 plus pt-5 " style={{color:'rgba(64, 64, 64, 1)'}}>Team Member</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>

                        <img src={prashant} alt="" className="img-fluid" style={{ height:'100px',width:'100px' }}/>
                        <p className="fw-600 fs-16 plus font-16 mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Prashant Raj</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>QA</p>

                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={kritesh} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 font-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Kritesh Singh</p>
                        <p className="fs-14 plus font-16" style={{color:'rgba(151, 151, 151, 1)'}}>Developer</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={ravi} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 font-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Ravi Chaudhary</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>DevOps</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={rajesh} alt="" className="img-fluid" />
                        <p className="fw-600 font-16 fs-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Rajesh Pandey</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Developer</p>
                </div>
         
            </div>
            
            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={harsh} alt="" className="img-fluid" />
                        <p className="fw-600 font-16 fs-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Harsh Rohilla</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Designer</p>
                </div>
         
            </div>
           </div>
            </div>
           
        </section>


        <section >
            <div className="container mt-lg-5 mt-30 ">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <img src={casestudyimg4} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 textcenter">

                        <p className="fw-700 font-24 fs-30 plus pt-20 mt-20">Developed Wireframes for Every Screens</p>

                        <p className="fs-18 font-16 plus" style={{color:'rgba(46, 46, 46, 0.6)'}}>Wireframes were created to outline the doctor’s workflow, focusing on functionality and ease of access. The design phase included creating a professional and intuitive interface that allows doctors to manage their practice efficiently. </p>
                    </div>
                </div>

            </div >   
        </section>

        <section>
            <div className=" mt-lg-5 mt-20">
                <img src={smartcareimg2} alt="" className="img-fluid" />
            </div>
        </section>

        <section className="mt-4 text-center">
 <p className="fw-600 fs-40 plus newfont-24  ">Our Next Project</p>

<div className="container projectcontainer mt-4">
  <div className="row justify-content-center">
  {smartcareData?.map((item1, index) => (
    <div className="col-lg-6 mb-5" eventKey={index}>
    <a href={item1.url} style={{textDecoration:'none'}}><div className="projectresponsive-box py-4 px-4 " style={{backgroundColor:item1.bgcolor}}>
      <div className="projectimage-container">
          <img src={item1.img} alt="" className="projectresponsive-img img-fluid hover1" />
        </div>
        <h1 className="projecttitle">{item1.heading}</h1>
        <p className="projectdescription">
         {item1.title}
        </p>
       
      </div></a>
    </div>
    )  )}
  </div>
</div> 
 </section>

     

<section className=''>
        <div className='newhome-bg1 resnewhome-bg1'>  
  <div className='container p-30'>
    <div className='row justify-content-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <p className='fs-50 fw-600 plus mb-0 text-white font-25 mt-35' style={{marginTop:'200px'}}>Contact for your </p>
          <p className='fs-50 fw-700 plus mt0 font-25 mt-35' style={{color:'rgba(255, 162, 49, 1)'}}> New Website</p>

      </div>
      <div className='col-lg-4 col-md-6 col-12'>
         <div>
            <Form/>
         </div>
        </div>
    </div>
  </div>
  </div>
  
</section>
       


<section>
  <div>
    <Chatbox/>
  </div>
</section>

            <div className="">
                <Footer/>
            </div>


        </div>

    );
};
export default SmartcareProject;